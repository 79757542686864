import gql from 'graphql-tag'

export const getCampaignStats = gql`
  query GetCampaignStats(
    $register: Boolean = false
    $login: Boolean = false
    $masterDataStarted: Boolean = false
    $masterDataPersonalDetailsAd: Boolean = false
    $addedMasterData: Boolean = false
    $createdBankAccount: Boolean = false
    $kycRedirectedToIdNow: Boolean = false
    $kycPart1Finished: Boolean = false
    $kycRedirectedToChat: Boolean = false
    $kycFinished: Boolean = false
    $beginVaultCreation: Boolean = false
    $vaultTypeSelected: Boolean = false
    $vaultCreated: Boolean = false
    $prepaidProcessStarted: Boolean = false
    $vaultDetailsCopied: Boolean = false
    $transferIbanCopied: Boolean = false
    $instantOrderStarted: Boolean = false
    $instantOrderChoseCurrency: Boolean = false
    $instantOrderChoseValue: Boolean = false
    $instantOrderSent: Boolean = false
    $instantOrderExecuted: Boolean = false
    $futureOrderStarted: Boolean = false
    $futureOrderChoseCurrency: Boolean = false
    $futureOrderChoseDate: Boolean = false
    $futureOrderChoseValue: Boolean = false
    $futureOrderChoseLimit: Boolean = false
    $futureOrderSent: Boolean = false
    $futureOrderExecuted: Boolean = false
    $repeatOrderStarted: Boolean = false
    $repeatOrderChoseCurrency: Boolean = false
    $repeatOrderChoseDate: Boolean = false
    $repeatOrderPlanChoseValue: Boolean = false
    $repeatOrderPlanChoseLimit: Boolean = false
    $repeatOrderPlanSent: Boolean = false
    $repeatOrderExecuted: Boolean = false
    $masterDataPersonalDetailsAdded: Boolean = false
    $revenue: Boolean = false
    $cost: Boolean = false
    $startDate: Datetime
    $endDate: Datetime
  ) {
    marketingStatistics(startDate: $startDate, endDate: $endDate) {
      nodes {
        channel
        campaign
        adGroup
        ad
        register @include(if: $register)
        login @include(if: $login)
        masterDataStarted @include(if: $masterDataStarted)
        masterDataPersonalDetailsAd @include(if: $masterDataPersonalDetailsAd)
        addedMasterData @include(if: $addedMasterData)
        createdBankAccount @include(if: $createdBankAccount)
        kycRedirectedToIdNow @include(if: $kycRedirectedToIdNow)
        kycPart1Finished @include(if: $kycPart1Finished)
        kycRedirectedToChat @include(if: $kycRedirectedToChat)
        kycFinished @include(if: $kycFinished)
        beginVaultCreation @include(if: $beginVaultCreation)
        vaultTypeSelected @include(if: $vaultTypeSelected)
        vaultCreated @include(if: $vaultCreated)
        prepaidProcessStarted @include(if: $prepaidProcessStarted)
        vaultDetailsCopied @include(if: $vaultDetailsCopied)
        transferIbanCopied @include(if: $transferIbanCopied)
        instantOrderStarted @include(if: $instantOrderStarted)
        instantOrderChoseCurrency @include(if: $instantOrderChoseCurrency)
        instantOrderChoseValue @include(if: $instantOrderChoseValue)
        instantOrderSent @include(if: $instantOrderSent)
        instantOrderExecuted @include(if: $instantOrderExecuted)
        futureOrderStarted @include(if: $futureOrderStarted)
        futureOrderChoseCurrency @include(if: $futureOrderChoseCurrency)
        futureOrderChoseDate @include(if: $futureOrderChoseDate)
        futureOrderChoseValue @include(if: $futureOrderChoseValue)
        futureOrderChoseLimit @include(if: $futureOrderChoseLimit)
        futureOrderSent @include(if: $futureOrderSent)
        futureOrderExecuted @include(if: $futureOrderExecuted)
        repeatOrderStarted @include(if: $repeatOrderStarted)
        repeatOrderChoseCurrency @include(if: $repeatOrderChoseCurrency)
        repeatOrderChoseDate @include(if: $repeatOrderChoseDate)
        repeatOrderPlanChoseValue @include(if: $repeatOrderPlanChoseValue)
        repeatOrderPlanChoseLimit @include(if: $repeatOrderPlanChoseLimit)
        repeatOrderPlanSent @include(if: $repeatOrderPlanSent)
        repeatOrderExecuted @include(if: $repeatOrderExecuted)
        masterDataPersonalDetailsAdded
          @include(if: $masterDataPersonalDetailsAdded)
        revenue @include(if: $revenue)
        cost @include(if: $cost)
      }
    }
  }
`
