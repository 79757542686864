import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { ColumnLabels } from '.'

export interface DialogSelectProps {
  isOpen: boolean
  handleOpen: () => void
  handleOk: (selected: ColumnLabels) => void
  handleClose: () => void
  options: ColumnLabels
  labels: Record<ColumnLabels[number] /*value*/, string /*label*/>
  currentSelection: ColumnLabels
}

export const DialogSelect = ({
  isOpen,
  handleOk,
  handleClose,
  handleOpen,
  options,
  labels,
  currentSelection,
}: DialogSelectProps) => {
  const [selection, setSelection] = useState<ColumnLabels>([])

  useEffect(() => {
    if (isOpen) {
      setSelection(currentSelection)
    }
  }, [isOpen]) // don't care when currentSelection changes, as serves as an initial state only when opening the dialog

  return (
    <>
      <Button onClick={handleOpen} size="small" variant="outlined">
        Add/remove events
      </Button>
      <Dialog disableEscapeKeyDown open={isOpen} onClose={handleClose}>
        <DialogTitle>Select the desired events</DialogTitle>
        <DialogContent>
          <Box>
            <FormControl>
              <Autocomplete
                multiple
                freeSolo={false}
                value={selection}
                options={options}
                onChange={(_, newVal) => setSelection(newVal)}
                getOptionLabel={(option) => labels[option] ?? option}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Event" />
                )}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleOk(selection)}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
